<template>
	<div class="ysm-order-three">
		<!-- 信息 -->
		<div align="left" class="left"  >
				<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
				<div>
					<h3 data-i18n="[html]forms.order.patient_information">留言</h3>
					<p data-i18n="forms.order.patient_information_content">如有特殊信息请在此处留言</p>
				</div>
		</div>
		<div class="right" align="left" style="width: 45%;">
			<div id="zlcon" style="margin-top: 0.625rem;z-index: 0;"></div>
			<div style="display: flex;justify-content: space-between;">
				<div style="width: 400px;"><span v-show="isShow" style="color: red;">编辑内容不能超过50个字</span></div>
				<span class="wordNumber">{{LyLength}}/50</span>
			</div>
			<div align="right" style="margin-top: 0.625rem;">
				<el-button class="back" type="primary" @click="back">返回</el-button>
				<el-button class="nextStep" type="primary" @click="nextStepOrder">下一步</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import E from 'wangeditor';
	
	export default{
		data:function(){
			return{
				isShow:false,
				LyLength:0,
				ruleForm:{
					oid:'',
					liuyan:''
				},
				editor:''
			}
		},
		methods:{
			nextStepOrder(){
				let ins=this.editor.txt.text();
				this.ruleForm.liuyan=ins;
				this.$axios.post("/client/order/addYsmLiuyan",JSON.stringify(this.ruleForm),{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					if(res.data.code=200){
						this.$message.success("留言成功");
					}
					this.$store.commit("setYsmStepIndex",9);
					this.$router.push("/ysmmain/ysmorder/ysmordernine");
				}).catch(err=>{
					this.$message.error("留言失败");
				})
			},
			//返回
			back(){
				this.$store.commit("setYsmStepIndex",7);
				this.$router.push("/ysmmain/ysmorder/ysmorderseven")
			}
			
		},
		mounted:function(){
			this.editor = new E('#zlcon')
			let _this=this;
			this.editor.config.onchange=function(e){
				console.log(e);
				var reg = /<[^<>]+>/g; //去标签
				var value = e.replace(reg, "");
				      value = value.replace(/&nbsp;/gi, ""); //将空格全部替换
				    
					 let n= value.length; //文字长度显示
					
					 if(n<50){
						  _this.isShow=false;
						_this.LyLength=n;
						_this.planDoctor=e;
						
					 }else if(n==50){
						 _this.isShow=true;
						 _this.LyLength=n;
						 _this.planDoctor=e;
					 }else{
						 _this.$nextTick(() => {
						 _this.editor.txt.text(_this.planDoctor);
						 }); 
					 }
					
				console.log(value.length);
			}
			this.editor.config.menus = [
				'head', // 标题
				'bold', // 粗体
				'fontSize', // 字号
				'fontName', // 字体
				'italic', // 斜体
				'underline', // 下划线
				'strikeThrough', // 删除线
				'foreColor', // 文字颜色
				// ‘backColor’, // 背景颜色
				// ‘link’, // 插入链接
				// ‘list’, // 列表
				// ‘justify’, // 对齐方式
				// ‘quote’, // 引用
				// ‘emoticon’, // 表情
				// ‘image’, // 插入图片
				// ‘table’, // 表格
				// ‘video’, // 插入视频
				// ‘code’, // 插入代码
				// ‘undo’, // 撤销
				// ‘redo’ // 重复
			]
			this.editor.create();
			this.$store.commit("setYsmStepIndex",8);
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")	
			//设置返回路径
			this.$store.commit("setBackLocation","/ysmmain/ysmorder/ysmorderseven");
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
			console.log(oid);
			if(oid!="undefined"&&oid!=''&&oid!=null&&oid!=undefined){
					this.ruleForm.oid=oid;
					//根据oid查询订单信息
					this.$axios.get("/client/order/getOrderInfo",{
						params:{
							oid:this.ruleForm.oid
						}
					}).then(res=>{
						let orderInfo=res.data.data;
						this.editor.txt.text(orderInfo.plan.liuyan)
					}).catch(err=>{
						console.log(err);
					});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/ysmmain/ysmorder/ysmorderone");
					}
				});
				
			}
		}
	}
</script>

<style scoped="scoped">
	.ysm-order-three{
		width: 80%;
		display: flex;
		margin: 0px auto;
		
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
	}
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	#zlcon{
		width: 560px;
	}
	#zlcon div{
		z-index: 0 !important;
	}
	/* 右边 */
	.right{
		/* position: relative;
		left: 9.55rem; */
		width: 560px !important;
	}
	/* 按钮 */
	.nextStep{
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
</style>
